import api from "../api";

export default {
    getDemographics() {
        return api().get("Route/GetDemographics");
    },

    getGranularityAudiences() {
        return api().get("Route/GetGranularityAudiences");
    },

    GetDashboardAnalytics(campaignId) {
        return api().get(`Route/GetDashboardAnalytics?campaignId=${campaignId}`);
    },
    
    getDashboardTargets(campaignBurstId) {
        return api().get(`Route/GetDashboardTargets?campaignBurstId=${campaignBurstId}`);
    },

    getDashboardTargetsByCampaign(campaignId,routeRequestId) {
        return api().get(`Route/GetDashboardTargetsByCampaign?campaignId=${campaignId}&routeRequestId=${routeRequestId}`);
    },
    
    updateDashboardTargets(data) {
        return api().put("Route/UpdateDashboardTargets", data);
    },

    getDashboardVerifications(campaignId,routeRequestId) {
        return api().get(`Route/GetDashboardVerifications?campaignId=${campaignId}&routeRequestId=${routeRequestId}`);
    },
    
    getRouteVersions() {
        return api().get('Route/GetRouteVersions')
    },
    setDashboardNotes(data) {
        return api().put('Route/SetDashboardNote', data)
    },
    getDashboardNotes(id) {
        return api().get('Route/GetDashboardNotes?campaignBurstId='+id)
    },
    getCampaignRouteRequests(id) {
        return api().get('Route/getCampaignRouteRequests?campaignId='+id)
    },
    addCampaignRouteRequest(data) {
        return api().post('Route/addCampaignRouteRequest', data)
    },
    getCampaignRCF(campaignId, routeRequestId) {
        return api().get(`Route/getCampaignRCF?campaignId=${campaignId}&routeRequestId=${routeRequestId}`)
    }
}