import CampaignController from '@/services/controllers/Campaign'
import moment from 'moment'

export default {
  methods: {
	async downloadArtworkSpecs(campaign,burst) {
      var bid = burst ? burst.id : 0;
      let response = await CampaignController.getArtworkSpecs(campaign.id,bid)

      let fileName = `DOOHcom ArtworkSpecs for ${campaign.name} - All Bursts - LIVE DATE_${moment(campaign.startDate).format('DD MMMM YYYY')} - ${moment(campaign.endDate).format('DD MMMM YYYY')} - All Media Owners. CREATED_${moment().format('DD MMMM YYYY - HH:mm:ss')}.xlsx`
      if(bid > 0)
        fileName = `DOOHcom ArtworkSpecs for ${campaign.name} - ${burst.name} - LIVE DATE_${moment(burst.startDate).format('DD MMMM YYYY')} - ${moment(burst.endDate).format('DD MMMM YYYY')} - All Media Owners. CREATED_${moment().format('DD MMMM YYYY - HH:mm:ss')}.xlsx`

      const url = URL.createObjectURL(new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }))

      const link = document.createElement('a')

      link.href = url

      link.setAttribute('download', fileName)

      document.body.appendChild(link)

      link.click()
    }
  }
}