<template>
  <TransitionGroup tag="div" name="fade">
    <CampaignView :key="$route.query.cid + '-container'"/>
  </TransitionGroup>
</template>

<script>
import CampaignView from '@/views/dashboard/campaigns/campaign/CampaignView.vue'
export default {
  data() {
    return {
      campaignId: null,
    };
  },
  components: {
    CampaignView
  },
  watch: {
    '$route.query.cid': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.campaignId = newVal;
      }
    }
  }
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
opacity: 1;
transition: all 250ms ease; 
}

.fade-enter, .fade-leave-to {
opacity: 0;
transform: scale(0.995);
}

</style>